import React from 'react';
import { TextField } from '@mui/material';

const EmailField = (props) => {

    const handleChange = (event) => {
        props.setEmailId(event.target.value)
    }
    return(
        <>
            <TextField margin="normal" required fullWidth id="email" type="email" label="Email Address" name="email" onChange={handleChange}/>
        </>
    )
}

export default EmailField