import React from "react";
import { useRoutes } from 'react-router-dom';
import ProtectedRoute from "./utils/protectedRoute";
import Login from "./pages/login/login";
import Dashboard from "./pages/dashboard/dash";
// import APIDocumentation from "./pages/docs/docs";
import APIDocumentation from "./pages/docs/newDocs";

const AppRoutes = () => {
    let routes = useRoutes([
        { path : '/', element : <Login/>},
        { path : '/dashboard', element : <ProtectedRoute><Dashboard></Dashboard></ProtectedRoute>},
        { path : '/docs', element : <ProtectedRoute><APIDocumentation/></ProtectedRoute>}
    ])
    return routes
}

export default AppRoutes