// src/components/APIDocumentation.js
import React from "react";
import { Typography, Box, Paper, Divider} from "@mui/material";
import PrasanviHeader from "../../components/dashboard/header";

const APIDocumentation = () => {
  return (
    <>
    <PrasanviHeader></PrasanviHeader>
    <Box p={3} bgcolor="#f5f5f5" minHeight="100vh">
        <Paper elevation={3} sx={{ padding: 5, maxWidth: "1000px", margin: "auto" }}>
            <Typography variant="h4" gutterBottom color="primary">PSEC API Documentation</Typography>

            <SectionTitle title="1. User Management API" />

            <EndpointSection
                title="1.1 Create User"
                method="POST"
                endpoint="/auth/register"
                description="Create a new user in the system."
                requestHeaders={[
                    { key: "Authorization", value: "Bearer <token>" },
                    { key: "Content-Type", value: "application/json" }
                ]}
                requestBody={`
                {
                    "username": "John Doe",
                    "email_id": "johndoe@example.com",
                    "password": "password123",
                    "mobile_number" : "1234567890",
                    "country" : "India"
                }`}
                responseCodes={[
                    { code: "201 Created", body: `
                    {
                        "id": 1,
                        "username": "John Doe",
                        "email_id": "johndoe@example.com",
                        "created_at": "2024-09-25T14:00:00Z"
                    }` },
                    { code: "422 Unprocessable Entity", body: `
                    {
                        "Email Id" : "Email is not Valid.",
                        "Password" : "Password should contain one lower case, one upper case, one number and a special character!!"
                    }` },
                    { code: "409 Conflict Error", body: `
                    {
                        "error": "Email is already taken"
                    }` },
                ]}
            />

            <EndpointSection
                title="1.2 Login"
                method="POST"
                endpoint="/auth/login"
                description="User Login."
                requestHeaders={[
                    { key: "Content-Type", value: "application/json" }
                ]}
                requestBody={`
                    {
                        "email_id": "johndoe@example.com",
                        "password": "password123",
                    }`}
                responseCodes={[
                    { code: "200 OK", body: `{
                        "access_token" : "<access-token>",
                        "token_type" : "Bearer",
                        "expires" : "%Y-%m-%d %H:%M:%S"
                    }` },
                    { code: "404 Not Found", body: `{
                        "error": "User Id not found"
                    }` },
                    { code: "422 Unprocessable Entity", body: `
                        {
                            "Email Id" : "Email is not Valid.",
                            "Password" : "Password should contain one lower case, one upper case, one number and a special character!!"
                    }` }
                ]}
            />

            <SectionTitle title="2. Static Analysis API" />

            <EndpointSection
                title="1.1 Meta Analysis"
                method="POST"
                endpoint="/static/meta"
                description="Meta Analysis."
                requestHeaders={[
                    { key: "Content-Type", value: "multipart/form-data" }
                ]}
                requestBody={`
                    {
                        "file_1": "Chess.apk",
                    }`}
                responseCodes={[
                    { code: "200 OK", body: `{
                        "meta" : {
                            "app_name" : "chess",
                            "package_name" : "com.jetstartgames.chess",
                            "file_size" : "3.04 MB",
                            "md5" : "a6640849bb820a969b38576cef1dd85a",
                            "sha1" : "42af7d449256ee4c7ea8b530ccb6434527fb4648",
                            "sha256" : "02603b05d5dc7d00d6538aa2beaee5cad0cd90c7a1e67cb017",
                            "target_sdk" : "33",
                            "min_sdk" : "21",
                            "max_sdk" : "33",
                            "android_version_name" : "2.8.7",
                            "android_version_code" : "82",
                        }
                    }` },
                    { code: "422 Unprocessable Entity", body: `
                        {
                            "File" : "Allowed File Types are .apk.",
                    }` }
                ]}
            />

            <EndpointSection
                title="1.2 Certificate Analysis"
                method="POST"
                endpoint="/static/cert"
                description="Certificate Analysis."
                requestHeaders={[
                    { key: "Content-Type", value: "multipart/form-data" }
                ]}
                requestBody={`
                    {
                        "file_1": "Chess.apk",
                    }`}
                responseCodes={[
                    { code: "200 OK", body: `{
                        "cert" : {
                            "info" : {
                                "high" : 0,
                                "warning" : 0,
                                "info" : 0
                            },
                            "data" : {
                                [
                                    {
                                        "title" : "Application vulnerable to Janus Vulnerability",
                                        "severity" : "warning",
                                        "description" : "Application is signed with v1 signature scheme, making it vulnerable to Janus vulnerability on Android 5.0-8.0"
                                    }
                                ]
                            }
                        }
                    }` },
                    { code: "422 Unprocessable Entity", body: `
                        {
                            "File" : "Allowed File Types are .apk.",
                    }` }
                ]}
            />

            <EndpointSection
                title="1.3 Network Analysis"
                method="POST"
                endpoint="/static/network"
                description="Network Analysis."
                requestHeaders={[
                    { key: "Content-Type", value: "multipart/form-data" }
                ]}
                requestBody={`
                    {
                        "file_1": "Chess.apk",
                    }`}
                responseCodes={[
                    { code: "200 OK", body: `{
                        network : {
                            "info" : {
                                "high" : 0,
                                "warning" : 0,
                                "info" : 0,
                                "secure" : 0
                            },
                            "data" : {
                                [
                                    {
                                        "scope" : " * ",
                                        "severity" : "warning",
                                        "description" : "Base config is insecurely configured"
                                    }
                                ]
                            }
                        }   
                    }` },
                    { code: "422 Unprocessable Entity", body: `
                        {
                            "File" : "Allowed File Types are .apk.",
                    }` }
                ]}
            />

            <EndpointSection
                title="1.4 Manifest Analysis"
                method="POST"
                endpoint="/static/manifest"
                description="Manifest Analysis."
                requestHeaders={[
                    { key: "Content-Type", value: "multipart/form-data" }
                ]}
                requestBody={`
                    {
                        "file_1": "Chess.apk",
                    }`}
                responseCodes={[
                    { code: "200 OK", body: `{
                        manifest : {
                            "info" : {
                                "high" : 0,
                                "warning" : 0,
                                "info" : 0,
                                "supressed" : 0
                            },
                            data : {
                                [
                                    {
                                        "issue" : "App can be installed on a vulnerable upatched Android version",
                                        "severity" : high",
                                        "description" : "This application can be installed on an older version of android that has multiple unfixed vulnerabilities."
                                    }
                                ]
                            }
                        }
                    }` },
                    { code: "422 Unprocessable Entity", body: `
                        {
                            "File" : "Allowed File Types are .apk.",
                    }` }
                ]}
            />

            <EndpointSection
                title="1.5 Application Permissions"
                method="POST"
                endpoint="/static/app_perm"
                description="Application Permission Analysis."
                requestHeaders={[
                    { key: "Content-Type", value: "multipart/form-data" }
                ]}
                requestBody={`
                    {
                        "file_1": "Chess.apk",
                    }`}
                responseCodes={[
                    { code: "200 OK", body: `{
                        "permissions" : {
                            "summary" : {
                                "dangerous" : 0,
                                "normal" : 0,
                                "signatureorsystem" : 0,
                                "signature" : 0,
                                "unknown" : 0
                            }
                            "data" : {
                                [
                                    {
                                        "info" : "allow app to access the device's advertising ID.",
                                        "status" : "normal",
                                        "permission" : "android.permission.ACCESS_ADSERVICES_AD_ID",
                                        "description" : "This ID is a unique, user-resettable identifier provided by Google's advertising services"
                                    }
                                ]
                            }
                        }
                    }` },
                    { code: "422 Unprocessable Entity", body: `
                        {
                            "File" : "Allowed File Types are .apk.",
                    }` }
                ]}
            />

            <EndpointSection
                title="1.6 APKiD Analysis"
                method="POST"
                endpoint="/static/apkid"
                description="APKiD Analysis."
                requestHeaders={[
                    { key: "Content-Type", value: "multipart/form-data" }
                ]}
                requestBody={`
                    {
                        "file_1": "Chess.apk",
                    }`}
                responseCodes={[
                    { code: "200 OK", body: `{
                        "apk_id" : {
                            "data" : {
                                [
                                    {
                                        "dex" : "classes.dex",
                                        "detections" : [
                                            {
                                                "finding" : "compiler",
                                                "details" : "r8 without marker (suspicious)"
                                            }
                                        ]
                                    }
                                ]
                            }
                        }
                    }` },
                    { code: "422 Unprocessable Entity", body: `
                        {
                            "File" : "Allowed File Types are .apk.",
                    }` }
                ]}
            />

            <EndpointSection
                title="1.7 CODE Analysis"
                method="POST"
                endpoint="/static/code"
                description="Code Analysis."
                requestHeaders={[
                    { key: "Content-Type", value: "multipart/form-data" }
                ]}
                requestBody={`
                    {
                        "file_1": "Chess.apk",
                    }`}
                responseCodes={[
                    { code: "200 OK", body: `{
                        code : {
                            "info" : {
                                "high" : 0,
                                "warning" : 0,
                                "info" : 0,
                                "secure" : 0,
                                "supressed" : 0
                            },
                            "data" : {
                                [
                                    {
                                        "issue" : "App uses SQLite Database and execute raw SQL query.",
                                        "severity" : "warning",
                                        "standards" : "OWASP Top 10: M7: Client CodeQuality",
                                    }
                                ]
                            }
                        }
                    }` },
                    { code: "422 Unprocessable Entity", body: `
                        {
                            "File" : "Allowed File Types are .apk.",
                    }` }
                ]}
            />

            <EndpointSection
                title="1.8 Malware Analysis"
                method="POST"
                endpoint="/static/malware"
                description="Malware Analysis."
                requestHeaders={[
                    { key: "Content-Type", value: "multipart/form-data" }
                ]}
                requestBody={`
                    {
                        "file_1": "Chess.apk",
                    }`}
                responseCodes={[
                    { code: "200 OK", body: `{
                        "malware" : {
                        }
                        "abused_permissions": {
                            "other_abused_permissions": [
                                "com.google.android.gms.permission.AD_ID",
                                "android.permission.FOREGROUND_SERVICE",
                                "com.google.android.finsky.permission.BIND_GET_INSTALL_REFERRER_SERVICE"
                            ],
                            "top_malware_permissions": [
                                "android.permission.INTERNET",
                                "android.permission.ACCESS_NETWORK_STATE",
                                "android.permission.WAKE_LOCK"
                            ],
                            "total_malware_permissions": 24,
                            "total_other_permissions": 45
                        },
                        "quark_analaysis": []
                    }` },
                    { code: "422 Unprocessable Entity", body: `
                        {
                            "File" : "Allowed File Types are .apk.",
                    }` }
                ]}
            />
        </Paper>
    </Box>
    </>
  );
};

// Reusable component for section titles
const SectionTitle = ({ title }) => (
  <Typography variant="h5" gutterBottom color="secondary" mt={4}>
    {title}
  </Typography>
);

// Reusable component for individual endpoints
const EndpointSection = ({ title, method, endpoint, description, requestHeaders, requestBody, responseCodes }) => (
  <Box mb={4}>
    <Typography variant="h6" gutterBottom>{title}</Typography>
    <Typography><strong>Method:</strong> {method}</Typography>
    <Typography><strong>Endpoint:</strong> {endpoint}</Typography>
    <Typography><strong>Description:</strong> {description}</Typography>

    <Typography variant="subtitle1" mt={2}>Request Headers</Typography>
    {requestHeaders.map(header => (
      <Typography key={header.key}>{header.key}: {header.value}</Typography>
    ))}

    {requestBody && (
      <>
        <Typography variant="subtitle1" mt={2}>Request Body</Typography>
        <CodeBlock code={requestBody} />
      </>
    )}

    <Typography variant="subtitle1" mt={2}>Response Codes</Typography>
    {responseCodes.map(response => (
      <Box key={response.code} mb={2}>
        <Typography variant="body2"><strong>{response.code}:</strong></Typography>
        <CodeBlock code={response.body} />
      </Box>
    ))}

    <Divider />
  </Box>
);

// Reusable component for rendering code blocks
const CodeBlock = ({ code }) => (
  <Paper variant="outlined" sx={{ padding: 2, backgroundColor: "#f0f0f0", fontFamily: "monospace", whiteSpace: "pre-wrap" }}>
    {code}
  </Paper>
);

export default APIDocumentation;
