import React from "react";
import { TextField } from "@mui/material";

const Password = (props) => {

    const handleChange = (event) => {
        props.setPassword(event.target.value)
    }

    return (
        <>
            <TextField margin="normal" required fullWidth id="password" type="password" label="Password" name="password" onChange={handleChange}/>
        </>
    )
}

export default Password