import React from "react";
import { Button } from "@mui/material";

const SubmitButton = (props) => {
    return(
        <>
        <Button type="button" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={props.handleSubmit}> Sign In </Button>

        </>
    )
}

export default SubmitButton