import React, { useState } from "react";
import { Box, TextField } from "@mui/material";


const LoginInputs = (props) => {
    // const [data, setData] = useState({})
    // var data = {}
    // const handleChange = (event) => {
    //     console.log(event.target.id, 'id bro')
    //     console.log(event.target.value, 'value bro')
    //     // data = {}
    //     data[event.target.id] = event.target.value;
        
    //     props.setFormData(data)
        
    // }
    const [data, setData] = useState({
        email_id: '',
        password: ''
    });

    const handleChange = (event) => {
        const { id, value } = event.target;

        // Update the state with new input values
        setData((prevData) => ({
            ...prevData,
            [id]: value,
        }));

        // Propagate the updated form data to parent component
        props.setFormData({
            ...data,
            [id]: value
        });
    };
    return (
        <>
            <Box width={'80%'} display={'flex'} gap={20} justifyContent={'center'}>
                <TextField margin="normal" onChange={handleChange} required id="email_id" name="email_id" type="email" label="Email Address" autoComplete="email" autoFocus size='small'/>
                <TextField margin="normal" onChange={handleChange} required id="password"  name="password" type="password" label="Password" autoComplete="current-password" size='small'/>            
            </Box>
        </>
    )
}

export default LoginInputs