import React from "react";
import { Box } from "@mui/material";
import LoginInputs from "./inputs/loginInputs";
import RegisterInputs from "./inputs/registerInputs";
import FileInputs from "./inputs/fileInputs";

const RequestInputs = (props) => {
    return (
        <>
            <Box className='response-box' component="fieldset" width={'90%'} height={100} my={4} marginLeft={5} display="flex" gap={4} p={2} sx={{border: '1px solid grey'}}>
                <legend>Inputs</legend>
                {props.analysisType === 'login'  &&
                    <LoginInputs setFormData={props.setFormData} formData={props.formData}></LoginInputs> 
                }

                {props.analysisType === 'deregister'  &&
                    <LoginInputs setFormData={props.setFormData} formData={props.formData}></LoginInputs> 
                }
                {props.analysisType === 'register'  &&
                    <RegisterInputs setFormData={props.setFormData} formData={props.formData}></RegisterInputs>
                }
                {props.componentType === 'analysis' && 
                    <FileInputs setFormData={props.setFormData} formData={props.formData}></FileInputs>
                }
            </Box>

        </>
    )
}

export default RequestInputs