import React from "react";
import { Navigate } from 'react-router-dom';
import myLocalStorage from "./shared_utils";


function ProtectedRoute({ children }) {
  const token = myLocalStorage.getItem('accessToken');
  return token ? children : <Navigate to="/" />;
}

export default ProtectedRoute;