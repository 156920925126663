import React from "react";
import { Box, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

const analysisByComponent = {
    auth : ['login', 'register', 'deregister'],
    analysis : ['meta', 'cert', 'network', 'app_perm', 'apkid', 'manifest', 'code', 'malware'],
    history : ['get_history', 'get_analysis', 'delete_analysis']
};
const contentData = {
    'login': 'Login',
    'register': 'Register',
    'deregister' : 'De-Register',
    'logout': 'Logout',
    'full': 'Full Analysis', 
    'meta': 'Meta Analysis', 
    'cert': 'Certificate Analysis', 
    'network' : 'Network Analysis', 
    'app_perm':'Application Permissions Analysis',
    'apkid': 'APKiD Analysis',
    'manifest' : 'Manifest Analysis',
    'code' : 'Code Analysis',
    'malware' : 'Malware Analysis',
    'get_history' : 'Get History',
    'get_analysis' : 'Get Historical Analysis',
    'delete_analysis' : 'Delete Analysis'
}

const RequestOptions = (props) => {


    const analysisOptions = React.useMemo(() => {
        return analysisByComponent[props.componentType].map((option) => (
                <MenuItem value={option} key={option}>{contentData[option]}</MenuItem>
        ))
    }, [props.componentType, contentData]);

    const handleComponentChange = (event) =>{
        props.setComponentType(event.target.value);
        props.setAnalysisType('');
    } 

    const handleSubmit = async () => {
        props.sendRequests();
    }


    return (
        <>
            <Box className='request-box' component="fieldset" width={'90%'} height={200} my={4} marginLeft={5} display="flex" flexDirection={'column'} gap={4} p={2} sx={{border: '1px solid grey'}}>
                <legend>Requests</legend>
                {/* <Box width={'100%'}>
                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                        <TextField margin='normal' value={props.analysisName} onChange={(e) => props.setAnalysisName(e.target.value)}  required id="analysis-name" type="text" label="Analysis Name" size='small' ></TextField>
                    </FormControl>
                </Box> */}
                <Box width={'100%'} display="flex" gap={4}>
                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel id="request-type-label">Request Type</InputLabel>
                        <Select labelId="request-type-label" id="request-type" value={props.requestType} label="Request Type" onChange={(e) => props.setRequestType(e.target.value)} size='small' required >
                            <MenuItem value={'get'} >GET</MenuItem>
                            <MenuItem value={'post'}>POST</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 320 }}>
                        <InputLabel id="component-label">Component</InputLabel>
                        <Select labelId="component-label" id="component" value={props.componentType} label="component" onChange={handleComponentChange} size='small' required >
                            <MenuItem value={'auth'} >Auth</MenuItem>
                            <MenuItem value={'analysis'}>Analysis</MenuItem>
                            <MenuItem value={'history'} disabled>History</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 320 }}>
                        <InputLabel id="analysis-label">Analysis</InputLabel>
                        <Select labelId="analysis-label" id="analysis" value={props.analysisType} label="Analysis" onChange={(e) => props.setAnalysisType(e.target.value)} size='small' defaultValue={'none'}>
                            <MenuItem key='none' value='none'></MenuItem>
                            {analysisOptions}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 220 }}>
                        <LoadingButton loading={props.loadingStatus} variant='contained' sx={{ ml : 2}} onClick={handleSubmit}>
                            Send Request
                        </LoadingButton>
                    </FormControl>
                </Box>
            </Box>
        </>
    )
}

export default RequestOptions