import { toast } from 'react-toastify';
import myLocalStorage from "../../utils/shared_utils";
import axiosInstance from "./axiosInstance";


async function ApiHandler(requestType, component, analysis, formData, setRequestValue, setResponseValue){
    console.log(formData, 'form data bro')
    let requestParams = ''
    let responseParams = ''
    if (component === 'auth'){
        if (analysis === 'login'){
            // if (validateLoginInputs(formData)){
            requestParams = {
                method: requestType,
                url: '/auth/login',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: formData,
                }
            try {
                console.log(formData, 'form data')
                const res = await axiosInstance({ method: requestType, url : '/auth/login', data : formData})
                responseParams = res.data
                toast.success('Login Request Successfull')
            } catch(err){
                if (!err.response){
                    console.log('In Error Response');
                    
                    responseParams = err.message
                    toast.error(err.message)
                } else {
                    // console.log(err.response.data.errors,'In Else Response');
                    // const errorData = err.response.data.errors
                    // if (! errorData){
                    //     console.log(err.response.data, 'data in error')
                    //     throw new Error(err.response.data.status)
                    // }
                    // responseParams = err.response
                    // console.log(responseParams, 'error data')
                    // const errorMessages = []
                    // Object.keys(errorData).forEach((key) => {
                    //     Object.keys(errorData[key]).forEach((item) => {
                    //         // toast.error(errorData[key][item])
                    //         const msg = errorData[key][item][0]
                    //         // throw
                    //         errorMessages.push(msg)
                    //     })
                    // })
                    if (err.response.data.status === 'Unprocessable Entity'){
                        const errorMessages = []
                        const errorData = err.response.data.errors
                        Object.keys(errorData).forEach((key) => {
                            Object.keys(errorData[key]).forEach((item) => {
                                // toast.error(errorData[key][item])
                                const msg = errorData[key][item][0]
                                // throw
                                errorMessages.push(msg)
                            })
                        })
                        throw new Error(errorMessages);
                    }
                    if (!err.response){
                        console.log(err.message, 'errro message')
                        toast.error(err.message)
                        throw new Error(err.message);
                    } else {
                        toast.error(err.response.data.message);
                        console.log(err.response, 'data message')
                        throw new Error(err.response.data.status);
                    }
                    // toast.error(err.response.data.message);
                }
            }
            setRequestValue(requestParams);
            setResponseValue(responseParams);
            return requestParams
        }
        else if (analysis === 'register'){
            formData['country'] = 'india'
            requestParams = {
                method: 'POST',
                url: '/auth/register',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: formData,
            }
            try{
                const res = await axiosInstance.post('/auth/register', formData)
                responseParams = res.data
                toast.success('Register Request Successfull')
            } catch(err){
                if (!err.response){
                    responseParams = err.message
                    toast.error(err.message)
                } else {
                    responseParams = err.response
                    toast.error(err.response.data.message);
                }
            }
            setRequestValue(requestParams);
            setResponseValue(responseParams);
            return requestParams
        } else if (analysis === 'deregister'){
            requestParams = {
                method: 'POST',
                url: '/auth/deregister',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: formData,
            }
            try {
                const res = await axiosInstance.post('/auth/register', formData)
                responseParams = res.data
                toast.success('De-Register Request Successfull')
            } catch(err){
                if (!err.response){
                    responseParams = err.message
                    toast.error(err.message)
                    throw new Error(err.message)
                } else {
                    responseParams = err.response
                    toast.error(err.response.data.message);
                    throw new Error(err.response.data.message)
                }
            }
            setRequestValue(requestParams);
            setResponseValue(responseParams);
            return requestParams
        }
    } else if (component === 'analysis'){
        if (! formData['file_upload']){
            toast.error('Please Upload APK File')
            return
        }
        const email_id = myLocalStorage.getItem('email_id')
        const access_token = myLocalStorage.getItem(email_id)
        const newFormData = new FormData();
        newFormData.append('file', formData['file_upload']);
        console.log(newFormData, 'new form data')
        requestParams = {
            method: 'POST',
            url: '/static/'+analysis,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: newFormData,
        }
        try {
            const res = await axiosInstance.post('/static/'+analysis, newFormData, {headers : {'Content-Type': 'multipart/form-data'}})
            // const res = await axiosInstance.post('/static/'+analysis, {files: formData})
            responseParams = res.data
            toast.success(analysis[0].toUpperCase() + analysis.slice(1)+' Analysis Request Successfull')
        } catch(err){
            if (!err.response){
                console.log('In Error Response');
                
                responseParams = err.message
                toast.error(err.message)
            } else {
                // console.log(err.response.data.errors,'In Else Response');
                const errorData = err.response.data.errors
                responseParams = err.response
                const errorMessages = []
                Object.keys(errorData).forEach((key) => {
                    Object.keys(errorData[key]).forEach((item) => {
                        // toast.error(errorData[key][item])
                        const msg = errorData[key][item][0]
                        // throw
                        errorMessages.push(msg)
                    })
                })
                throw new Error(errorMessages.join('|'));
                // toast.error(err.response.data.message);
            }
        }
        setRequestValue(requestParams);
        setResponseValue(responseParams);
        return requestParams
    } else if (component === 'history') {
        requestParams = {
            method: 'POST',
            url: '/static/'+analysis,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            data: {},
        }
        try {
            const res = await axiosInstance.get('/static/'+analysis)
            responseParams = res.data
            toast.success(analysis[0].toUpperCase() + analysis.slice(1)+' Analysis Request Successfull')
        } catch(err){
            if (!err.response){
                responseParams = err.message
                toast.error(err.message)
            } else {
                responseParams = err.response
                toast.error(err.response.data.message);
            }
        }
        setRequestValue(requestParams);
        setResponseValue(responseParams);
        return requestParams
    }
}


export default ApiHandler