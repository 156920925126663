import validator from 'validator';
import { toast } from 'react-toastify';

const myLocalStorage = {
    getItem(key) {
      // get the parsed value of the given key
      let result = JSON.parse(localStorage.getItem(key));
      
      // if the key has value
      if(result){ 
          
          // if the entry is expired
          // remove the entry and return null
          if(result.expireTime <= Date.now()){
              localStorage.removeItem(key);
              return null;
          }  
          
          // else return the value
          return result.data;
      }
      
      // if the key does not have value
      return null;
    },
    
    // add an entry
    // default expiry is 30 days in milliseconds
    setItem(key, value, maxAge = 10 * 60 * 1000) {
       // store the value as object
       // along with expiry date
        let result  = {
          data : value
       }
       
       
       if(maxAge){
         // set the expiry 
         // from the current date
         result.expireTime = Date.now() + maxAge;
       }
       
       // stringify the result
       // and the data in original storage
       localStorage.setItem(key, JSON.stringify(result));
    },
    
    // remove the entry with the given key
    removeItem(key) {
      localStorage.removeItem(key);
    },
    
    // clear the storage
    clear() {
      localStorage.clear();
    }
  };


export function validateLoginInputs(formData) {
    if (formData.email_id.length === 0 ) {
        toast.error('Email Field is Empty')
        return false;
    }
    if (!validator.isEmail(formData.email_id)) {
        toast.error('Email is Not Valid!!')
        return false;
    }
    if (formData.password.length === 0) {
        toast.error('Password Field is Empty')
        return false;
    }
    if (!validator.isStrongPassword(formData.password)) { 
        toast.error('Password should contain one lower case, one upper case, one number and a special character!!')
        return false;
    } 
    return true
}

export function validateRegisterInputs(formData){
    if (formData.username.length === 0){
      toast.error('Username Field is empty');
      return false
    }
    if (!validator.isAlpha(formData.username)) {
      toast.error('Username is Invalid, Username should only contain only letter (a-zA-Z)');
      return false
    }
    if (formData.email_id.length === 0 ) {
        toast.error('Email Field is Empty')
        return false;
    }
    if (!validator.isEmail(formData.email_id)) {
        toast.error('Email is Not Valid!!')
        return false;
    }
    if (formData.password.length === 0) {
        toast.error('Password Field is Empty')
        return false;
    }
    if (!validator.isStrongPassword(formData.password)) { 
        toast.error('Password should contain one lower case, one upper case, one number and a special character!!')
        return false;
    }
    if (formData.mobile_number.length === 0){
      toast.error('Mobile Number is empty');
      return false
    }
    if (!validator.isMobilePhone(formData.mobile_number, ['en-IN'])){
      toast.error('Mobile Number is Invalid')
      return false
    }
    return true
}

export function validateLogoutInputs(formData){
    if (formData.email_id.length === 0 ) {
        toast.error('Email Field is Empty')
        return false;
    }
    if (!validator.isEmail(formData.email_id)) {
        toast.error('Email is Not Valid!!')
        return false;
    }
    return true
}

export function validateAnalysisFile(formData, type){
  // if (!formData.file){
  //   toast.error('File not Found')
  //   return false
  // }
  if (type === 'application/vnd.android.package-archive'){
    return true
  } else {
    console.log('In error')
    toast.error('Please Upload a valid APK File')
    return false
    
  }
}
export default myLocalStorage;