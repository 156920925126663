import React, { useState } from "react";
import { Box, Tab, Typography } from "@mui/material";
import {TabPanel, TabContext, TabList} from "@mui/lab";

const RequestResponseOutput = (props) => {

    const [value, setValue] = useState('1')
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Box className='response-box' component="fieldset" width={'90%'} height={300} my={4} marginLeft={5} display="flex" gap={4} p={2} sx={{border: '1px solid grey'}}>
                <legend>Response</legend>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Request" value="1" />
                                <Tab label="Response" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            {props.requestValue?
                                <Box width={'100%'} sx={{height: 200, overflowY: 'auto'}}>
                                    <Typography variant="body1" component='pre' sx={{ whiteSpace: 'pre-wrap', backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '4px' }}>
                                        {JSON.stringify(props.requestValue, null, 2)}
                                    </Typography>
                                </Box>
                                : ""}
                        </TabPanel>
                        <TabPanel value="2">
                            {props.responseValue?     
                                <Box component={'pre'} sx={{ width: 1100, height: 200, overflowY: 'auto',overflowWrap: 'break-word', wordWrap: 'break-word', whiteSpace:'pre-wrap'}}  >
                                <Typography width={'100%'} variant="body1">
                                    {JSON.stringify(props.responseValue, null, 2)}
                                </Typography>
                            </Box>
                             : ""}
                        </TabPanel>
                    </TabContext>
                </Box>
            </Box>

        </>
    )
}

export default RequestResponseOutput