import React, {useEffect, useState} from "react";
import PrasanviHeader from "../../components/dashboard/header";
import RequestOptions from "../../components/dashboard/options";
import RequestInputs from "../../components/dashboard/inputs";
import RequestResponseOutput from "../../components/dashboard/inputs/requestResponseOutput";
import myLocalStorage from "../../utils/shared_utils";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ApiHandler from "../../components/common/ApiHandler";

const Dashboard = () => {


    const navigate = useNavigate();
    const [formData, setFormData] = useState({});
    const [analysisName, setAnalysisName] = useState('');
    const [requestType, setRequestType] = useState('get');
    const [analysisType, setAnalysisType] = useState('');
    const [componentType, setComponentType] = useState('auth');
    const [responseValue, setResponseValue] = useState('');
    const [requestValue, setRequestValue] = useState('');
    const [loadingStatus, setLoadingStatus] = useState(false);

    const sendRequests = async() => {

        setLoadingStatus(true);
        try {
            const res = await ApiHandler(requestType ,componentType, analysisType, formData, setRequestValue, setResponseValue)
        } catch(err) {
            const err_list = err.message.split(',');
            err_list.forEach((item) => {
                toast.error(item)

            })
        }
        setLoadingStatus(false);
    }
    useEffect(()=>{
        const token = myLocalStorage.getItem('accessToken');
        if (! token){
            toast.error('Session Expired, Please Login Again');
            navigate('/');
        }
    }, [])

    return (
        <>
            <PrasanviHeader></PrasanviHeader>
            <RequestOptions  sendRequests={sendRequests} requestType={requestType} setFormData={setFormData} formData={formData} loadingStatus={loadingStatus} setRequestValue={setRequestValue} setResponseValue={setResponseValue} setLoadingStatus={setLoadingStatus} analysisName={analysisName} setAnalysisName={setAnalysisName} setRequestType={setRequestType} componentType={componentType} analysisType={analysisType} setAnalysisType={setAnalysisType} setComponentType={setComponentType}></RequestOptions>
            <RequestInputs setFormData={setFormData} formData={formData} analysisType={analysisType} componentType={componentType}></RequestInputs>
            <RequestResponseOutput responseValue={responseValue} requestValue={requestValue}></RequestResponseOutput>
        </>
    )

}

export default Dashboard