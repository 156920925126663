import React,{useState} from "react";
import { Box, TextField } from "@mui/material";


const RegisterInputs = (props) => {

    // const handleChange = (event) => {

    //     const data = {};
    //     data[event.target.id] = event.target.value;
    //     props.setFormData(data);
    // }
    const [data, setData] = useState({
        email_id: '',
        password: '',
        username: '',
        mobile_number: ''
    });

    const handleChange = (event) => {
        const { id, value } = event.target;

        // Update the state with new input values
        setData((prevData) => ({
            ...prevData,
            [id]: value,
        }));

        // Propagate the updated form data to parent component
        props.setFormData({
            ...data,
            [id]: value
        });
    };
    return (
        <>
            <Box width={'80%'} display={'flex'} gap={5} justifyContent={'center'}>
                <TextField margin='normal' onChange={handleChange} required id="username" type="text" label="First Name" size='small' ></TextField>
                <TextField margin="normal" onChange={handleChange} required id="email_id" type="email" label="Email Address" name="email" autoComplete="email" autoFocus size='small' />
                <TextField margin="normal" onChange={handleChange} required name="password" label="Password" type="password" id="password" autoComplete="current-password" size='small' />            
                <TextField margin="normal" onChange={handleChange} required name="mobile_number" label="Mobile Number" type="tel" id="mobile_number" size='small'/>            
            </Box>
        </>
    )
}

export default RegisterInputs