import React, { useState } from "react";
import { Box, FormControl, Button, styled } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

const FileInputs = (props) => {

    const [uploaded, setUploaded] = useState(false);

    const handleChange = (event) => {

        const data = {};
        data[event.target.id] = event.target.files[0];
        props.setFormData(data)
        setUploaded(true)
    }
    return (
        <>
            <Box width={'80%'} display={'flex'} gap={5} justifyContent={'center'}>
                <FormControl sx={{ m: 1, minWidth: 220 }}>
                    <Button component="label" role={undefined} variant={uploaded?'contained':'outlined'} tabIndex={-1} startIcon={<CloudUploadIcon />} onChange={handleChange} >Upload file <VisuallyHiddenInput type="file" id="file_upload" /> </Button>
                </FormControl>
            </Box>
        </>
    )
}

export default FileInputs