import React ,{ useState } from "react";
import axios from 'axios';
import { Container, Box, Typography } from "@mui/material";
import {useNavigate} from 'react-router-dom';
import { toast } from "react-toastify";
import { validateLoginInputs } from "../../utils/shared_utils";
import myLocalStorage from "../../utils/shared_utils";
import EmailField from "../../components/login/emailField";
import Password from "../../components/login/passwordField";
import SubmitButton from "../../components/common/submitButton";
import './login.css'


const Login = () =>{

    const navigate = useNavigate();
    const [emailId, setEmailId] = useState('');
    const [password, setPassword] = useState('');

    const handleLoginSubmit = async(event) => {
        const formData = {
            email_id: emailId,
            password: password
        }
        const validationStatus = validateLoginInputs(formData)
        if (!validationStatus) {
            event.preventDefault();
            return
        }
        try {
            const res = await axios.post('https://psecapi.prasanvi.com/auth/login', formData)
            const access_token = res.data.access_token
            myLocalStorage.setItem('accessToken', access_token);
            myLocalStorage.setItem('email_id', formData.email_id);
            navigate('/dashboard')
            toast.success('Login Successfull')
        } catch(err){
            if (!err.response){
                toast.error(err.message)
            } else {
                toast.error(err.response.data.message);
            }
            event.preventDefault();
        }

    }
    return (
        <>
            <div class='center'>
                <Container component="main" maxWidth="xs">
                    <Box>
                        <Typography component="h1" variant="h5"> Sign In </Typography>
                        <Box component="form">  
                            <EmailField setEmailId={setEmailId}></EmailField>
                            <Password setPassword={setPassword}></Password>
                            <SubmitButton handleSubmit={handleLoginSubmit}></SubmitButton>
                        </Box>
                    </Box>
                </Container>
            </div>
        </>
    )
}

export default Login