import axios from 'axios';
import myLocalStorage from '../../utils/shared_utils';

// Create Axios instance
const axiosInstance = axios.create({
  baseURL: 'https://psecapi.prasanvi.com',  
});

// Add request interceptor (optional)
axiosInstance.interceptors.request.use(
  (config) => {
    // Add any custom headers or logic here (e.g., auth token)
    const token = myLocalStorage.getItem('accessToken');
    console.log(token, 'token')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    console.log('Request made with config:', config);
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add response interceptor (optional)
axiosInstance.interceptors.response.use(
  (response) => {
    // Process response data
    console.log('Response received:', response);
    return response;
  },
  (error) => {
    // Handle response error
    console.error('Response error:', error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
